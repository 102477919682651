import React from 'react';
import { IonBackButton, IonButtons, IonContent, IonHeader, IonPage, IonToolbar, withIonLifeCycle } from '@ionic/react';

//	S T Y L E

import './sb-try-nearby.scss';

//	C L A S S

class SbTryNearbyPage extends React.Component<{}, {}> {

//#region 																							V E R S I O N

	






//#endregion

//#region 																							L I F E C Y C L E

	ionViewWillEnter() : void {
	
		




	}

//#endregion

//#region 																							R E N D E R

	render() : React.ReactNode {
		return <>
			<IonPage>

				<IonHeader className="ion-no-border" collapse="fade">
					<IonToolbar>
						<IonButtons slot="start">
							<IonBackButton defaultHref="/settings"></IonBackButton>
						</IonButtons>
					</IonToolbar>
				</IonHeader>

				<IonContent>
					<section className="sb-try-nearby---container">
						<div>
							<img src="/assets/suggestion-nearby-logo.png" />
						</div>
						<br />
						<section>
							
							<h3 style={{textAlign:'center',fontWeight:'bold'}}>Scopri il mondo intorno a te</h3>
							
							<br />

							<p>Con Nearby Community, l'app che ti unisce al mondo che ti circonda ed a migliaia di persone con i tuoi stessi interessi</p>
							<p>Trova eventi, Punti di Interesse, ed Esperienze</p>
							<p>Disponibile ora su Play Store ed App Store, scaricala subito</p>

							<br />
							<br />

							<a target="_blank" href="https://apps.apple.com/us/app/nearby-community/id6633433866">
								<img src="/assets/stores/app-store.svg" />
							</a>

							<br />

							<a target="_blank" href="https://play.google.com/store/apps/details?id=com.prysmlab.nearby">
								<img src="/assets/stores/play-store.svg" />
							</a>

							<br />
							<br />
							<br />
							<br />

						</section>
					</section>
				</IonContent>

			</IonPage>
		</>;
	}

//#endregion

}

//	E X P O R T

export default withIonLifeCycle(SbTryNearbyPage);