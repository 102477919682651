import React from 'react'

//	S T Y L E

import './com-suggestion-nearby.scss'

//	L I B S

import { lib_names } from '../../libs/lib.names';

//	F U N C S

import { funcs_datetime } from '../../funcs/funcs.datetime';
import { IonCard, IonList } from '@ionic/react';

//	C L A S S

export default class ComSuggestionNearbyComponent extends React.Component<{}, {}> {

//#region 																							C O N S T R U C T O R

	private readonly funcs___datetime: funcs_datetime = new funcs_datetime();

//#endregion

//#region 																							R E N D E R

	render() : React.ReactNode {
		return <>
			<IonCard style={{padding: 0}}>
				<section className="com-suggestion-nearby---container">
					<IonList>
						<img src="assets/suggestion-nearby.png"/>
					</IonList>
				</section>
			</IonCard>
		</>;
	}

//#endregion

}