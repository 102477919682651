import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { IonApp, IonRouterOutlet, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';

//	C S S   D E F A U L T S

import '@ionic/react/css/core.css';
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

//	T H E M E

//import './theme/theme.reset.css';
import './theme/theme.variables.scss';
import './theme/theme.global.scss';
import { theme_constructor } from './theme/theme.constructor';

//	T Y P E S

import { type___api___response } from './types/types.api-response';

//	S T A T E   -   P R O P S

import { state___App, state___App___default } from './App.state';

//	L I B S

import { lib_names } from './libs/lib.names';

//	S E R V I C E S

import { service_RestAuthService } from './services/service-auth';
import { service_RestApiService } from './services/service-api';

//	P A G E S

import SbAnalyticsGlobalPage from './pages/sb-analytics/sb-analytics-global/sb-analytics-global';
import SbAnalyticsMePage from './pages/sb-analytics/sb-analytics-me/sb-analytics-me';

import SbCashdeskPage from './pages/sb-cashdesk/sb-cashdesk';

import SbDashboardPage from './pages/sb-dashboard/sb-dashboard';

import SbEventsImportPage from './pages/sb-events/sb-events-import/sb-events-import';
import SbEventsListPage from './pages/sb-events/sb-events-list/sb-events-list';
import SbEventsViewPage from './pages/sb-events/sb-events-view/sb-events-view';

import SbInfoAppPage from './pages/sb-info/sb-info-app/sb-info-app';
import SbInfoCopyrightPage from './pages/sb-info/sb-info-copyright/sb-info-copyright';
import SbInfoEulaPage from './pages/sb-info/sb-info-eula/sb-info-eula';
import SbInfoLicencesPage from './pages/sb-info/sb-info-licences/sb-info-licences';

import SbLoginPage from './pages/sb-login/sb-login';

import SbPaymentsUsersPage from './pages/sb-payments/sb-payments-users/sb-payments-users';
import SbPaymentUsersOverviewPage from './pages/sb-payments/sb-payments-users-overview/sb-payments-users-overview';

import SbSettingsMainPage from './pages/sb-settings/sb-settings-main/sb-settings-main';
import SbSettingsAuthenticationPage from './pages/sb-settings/sb-settings-authentication/sb-settings-authentication';
import SbSettingsProfilePage from './pages/sb-settings/sb-settings-profile/sb-settings-profile';
import SbSettingsThemePage from './pages/sb-settings/sb-settings-theme/sb-settings-theme';

import SbTicketsListPage from './pages/sb-tickets/sb-tickets-list/sb-tickets-list';
import SbTryNearbyPage from './pages/sb-try-nearby/sb-try-nearby';

import SbUsersEditPage from './pages/sb-users/sb-users-edit/sb-users-edit';
import SbUsersListPage from './pages/sb-users/sb-users-list/sb-users-list';

//	C O M P O N E N T S

import ComSpinnerComponent from './components/com-spinner/com-spinner';

//	M O D A L S

import MdSignPasswordChangeModal from './modals/md-sign-password-change/md-sign-password-change';

//	S E T U P

setupIonicReact();

//	C L A S S

export default class App extends React.Component<{}, state___App> {

//#region 																							D E C L A R A T I O N S

	private readonly _AUTH: service_RestAuthService = new service_RestAuthService();
	private readonly _API: service_RestApiService = new service_RestApiService();

	private readonly theme___constructor: theme_constructor = new theme_constructor();

//#endregion

//#region 																							C O N S T R U C T O R

	constructor(
		public props: {},
	) {
		super(props);
		this.state = state___App___default;
	}

//#endregion

//#region 																							R E A D   P R O P S

	read_props___me___is_logged = async () => {
		const ___is_logged: any | null = await this._AUTH.auth___checklogged();
		if (___is_logged['is_logged'] === true)
		{
			this.setState({
				me___is_logged: true
			}, async() => {
				await this.read_props___me___datas();
			});
		}
		else
		{
			this.setState({
				me___is_logged: false
			});
		}
	}

	read_props___me___datas = async () => {
		const ___me_props___results: type___api___response = await this._API.users___read___single('me', null);
		if (___me_props___results && ___me_props___results.response === 'success')
		{
			this.setState({
				me___props: ___me_props___results.data,
				me___props___is_loading: false,
			}, () => {
				this.setState({
					modal___password_change___visible: this.state.me___props['user_password___is_temporary'],
				}, async () => {
					await this.read_props___event_selected();
				});
			});
		}
		else
		{
			this.setState({
				me___props: null,
				me___props___is_loading: false,
			});
		}
	}

	read_props___event_selected = async () => {
		const ___stored___event_id: string | null = localStorage.getItem(lib_names.localStorage.user___event_selected);
		if (___stored___event_id !== null)
		{
			const ___temp___selected_event_props: type___api___response = await this._API.events___read___single(___stored___event_id);
			this.setState({
				selected_event___props: (___stored___event_id !== null) ? ___temp___selected_event_props.data : null,
				selected_event___props___is_loading: false
			});
		}
		else
		{
			this.setState({
				selected_event___props___is_loading: false
			})
		}
	}

//#endregion

//#region 																							H A N D L E R S

	handle___auth___set_props = (___me___props: any | null) => {
		this.setState({
			me___is_logged: (___me___props !== null) ? true : false,
			me___props: ___me___props,
			me___props___is_loading: false,
		});
	}

//#endregion

//#region 																							L I F E C Y C L E

	async componentDidMount() : Promise<void> {
		this.theme___constructor.theme___load();
		await this.read_props___me___is_logged();
	}

//#endregion

//#region 																							R E N D E R

	render() : React.ReactNode
	{
		return <>
			<IonApp>
				<MdSignPasswordChangeModal isOpen={ this.state.modal___password_change___visible } event___onDidDismiss={() => { this.setState({ modal___password_change___visible: false }); }}/>
				{(() => {
					switch (this.state.me___is_logged)
					{
						case true: return <>
							{(this.state.me___props___is_loading === true || this.state.selected_event___props___is_loading === true) ?<>
								<section>
									<br />
									<br />
									<br />
									<br />
									<br />
									<ComSpinnerComponent size="standard"/>
								</section>
							</> : <>
								<IonReactRouter>
									<IonRouterOutlet>

										{/*      d a s h b o a r d      */}

										<Route path="/analytics/global/:id" component={(___p: any) => { return <SbAnalyticsGlobalPage {...___p} me={ this.state.me___props } /> }} exact />
										<Route path="/analytics/me/:id" component={(___p: any) => { return <SbAnalyticsMePage {...___p} me={ this.state.me___props } /> }} exact />

										{/*      d a s h b o a r d      */}

										<Route path="/dashboard" component={(___p: any) => { return <SbDashboardPage {...___p} me={ this.state.me___props } /> }} exact />

										{/*      c a s h d e s k      */}

										<Route path="/cashdesk" component={(___p: any) => { return <SbCashdeskPage {...___p} me={ this.state.me___props } /> }} exact />

										{/*      e v e n t s      */}

										<Route path="/events/import/from/nearby/:id" component={(___p: any) => { return <SbEventsImportPage {...___p} me={ this.state.me___props } /> }} exact />
										<Route path="/events/list" component={(___p: any) => { return <SbEventsListPage {...___p} me={ this.state.me___props } /> }} exact />
										<Route path="/events/view/:id" component={(___p: any) => { return <SbEventsViewPage {...___p} me={ this.state.me___props } /> }} exact />

										{/*      i n f o s      */}

										<Route path="/info/app" component={(___p: any) => { return <SbInfoAppPage {...___p} me={ this.state.me___props } /> }} exact />
										<Route path="/info/copyright" component={(___p: any) => { return <SbInfoCopyrightPage {...___p} me={ this.state.me___props } /> }} exact />
										<Route path="/info/eula" component={(___p: any) => { return <SbInfoEulaPage {...___p} me={ this.state.me___props } /> }} exact />
										<Route path="/info/licences" component={(___p: any) => { return <SbInfoLicencesPage {...___p} me={ this.state.me___props } /> }} exact />
										
										{/*      p a y m e n t s      */}

										<Route path="/payments/users" exact><SbPaymentsUsersPage me={ this.state.me___props }/></Route>
										<Route path="/payments/userview/:id" component={(___p: any) => { return <SbPaymentUsersOverviewPage {...___p} me={ this.state.me___props } /> }} exact />

										{/*      t i c k e t s      */}

										<Route path="/tickets/list" exact><SbTicketsListPage me={ this.state.me___props }/></Route>

										{/*      u s e r s      */}

										<Route path="/users/list" component={(___p: any) => { return <SbUsersListPage {...___p} me={ this.state.me___props } /> }} exact />
										<Route path="/users/edit/:id" component={(___p: any) => { return <SbUsersEditPage {...___p} me={ this.state.me___props } /> }} exact />

										{/*      s e t t i n g s      */}

										<Route path="/settings" exact><SbSettingsMainPage /></Route>
										<Route path="/settings/authentication" exact><SbSettingsAuthenticationPage /></Route>
										<Route path="/settings/profile" exact><SbSettingsProfilePage /></Route>
										<Route path="/settings/theme" exact><SbSettingsThemePage /></Route>

										{/*      t r y   n e a r b y      */}

										<Route path="/try-nearby" exact><SbTryNearbyPage /></Route>

										{/*      r e d i r e c t      */}
										
										<Redirect to="/dashboard" />

									</IonRouterOutlet>
								</IonReactRouter>
							</>}
						</>; break;
						case false: return <>
							<SbLoginPage event___setMeProps={ this.handle___auth___set_props } />
						</>; break;
						default: return <>
							<section>
								<br />
								<br />
								<br />
								<br />
								<br />
								<ComSpinnerComponent size="standard"/>
							</section>
						</>; break;
					}
				})()}
			</IonApp>
		</>;
	}

//#endregion

}